import React from "react";
import { Link } from "gatsby"
import { useSiteContext } from "../contexts/site";

export default function Treatment({ item }) {

  const { locale } = useSiteContext();
  const moreButtonText = {
    'en': 'More',
    'ch': '更多'
  };

  return (
    <div className="w-full flex items-center">
      <img className="rounded-full w-32 h-32 lg:w-44 lg:h-44" src={item.thumbnail} alt={item.title}/>
      <aside className="ml-4 lg:mx-12">
        <h1 className="text-xl lg:text-2xl text-coconut font-medium mb-4">{item.title}</h1>
        <p className="text-base lg:text-lg text-brick mb-6">{item.description}</p>
        <Link href={item.href} className="text-sm lg:text-base text-white bg-coconut px-3 lg:px-5 py-2 lg:py-3 rounded-md">{moreButtonText[locale]}</Link>
      </aside>
    </div>
  )
}