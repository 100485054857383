import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Treatment from "../components/Treatment";

const AllTreatmentsTemplate = ({ pageContext, data }) => {
  const translations = {
    en: {
      treatmentTitle: 'Chiropractic Treatment Methods',
      treatmentDescription: 'To ensure that each patient receives treatment that is tailored to his/her specific needs, a range of natural and complementary therapeutic approaches may be employed by a chiropractor.',
    },
    ch: {
      treatmentTitle: '脊醫的治療方法',
      treatmentDescription: '為確保每位患者獲得針對其個別狀況度身訂造的治療，我們結合了多種自然的輔助療法，由傳統而簡單的針法，以致採用最先進的科技器材以達致理想的療效。',
    }
  }
  return (
    <Layout context={pageContext} openGraph={{
      url: pageContext.localizedUrls[pageContext.locale],
      title: pageContext.title,
      description: pageContext.description,
      images: [
        {
          url: `https://www.spinecentre.com.hk${data.firestore.page.content.headerImage}`
        }
      ]
    }} treatments={data.firestore.treatments} >
      <PageHeader
        title={data.firestore.page.content[pageContext.locale]['headerTitle']}
        imageUrl={data.firestore.page.content.headerImage}
      />
      <main className="my-12 flex flex-col justify-center items-center px-4 lg:px-8">
        <h1 className="text-coconut font-semibold text-2xl lg:text-3xl text-center">{translations[pageContext.locale]['treatmentTitle']}</h1>
        <hr className="border-mango border w-8 my-6"/>
        <p className="text-brick text-lg lg:text-xl text-center max-w-5xl mx-8">{translations[pageContext.locale]['treatmentDescription']}</p>
        <div className="my-8 max-w-7xl w-full grid gap-8 grid-cols-1 lg:grid-cols-2">
          {
            data.firestore.treatments.map(item => (
              <Treatment key={item.key} item={item} />
            ))
          }
        </div>
        
      </main>
    </Layout>
  )
}

export default AllTreatmentsTemplate

export const query = graphql`
  query AllTreatmentsTemplateQuery($locale: String! $pageId: ID) {
    firestore {
      page(id: $pageId) {
        content
      }
      treatments(locale: $locale) {
        id
        title
        href
        description
        thumbnail
      }
    }
  }
`